<template>
  <div class="box">
    <div class="dian">
      <img src="../../assets/mobile/download02.png" alt="" />
    </div>
    <div class="position">
      <div>
        <div class="dow_button" @click="contactChange('https://version.linglongkeji.im/lastVerson/Linglong_setup.exe')">
          <img src="../../assets/mobile/download.png" alt="" />
          <span class="dow_button_text">Windows 64位</span>
        </div>
      </div>
      <div style="margin-top: 60px">
        <el-carousel indicator-position="outside" height="220px">
          <el-carousel-item v-for="item in list" :key="item">
            <img :src="item.url" style="height: 200px" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="base">
      <img src="../../assets/mobile/download01.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          url: require("../../assets/mobile/carousel01.png"),
        },
        {
          url: require("../../assets/mobile/carousel02.png"),
        },
        {
          url: require("../../assets/mobile/carousel03.png"),
        },
      ],
    };
  },
  methods:{
        contactChange(url){
            window.open(url)
        }
    }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(228, 247, 255, 0) 0%,
    #e4f7ff 11%,
    #e4f7ff 100%
  );
  position: relative;
}
.dian {
  position: absolute;
  top: 34px;
  left: 0;
}
.position{
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}
.base {
  position: absolute;
  bottom: -4px;
  right: 0;
  z-index: 1;
}
.dow_button {
  width: 180px;
  height: 46px;
  background: #3f8cff;
  box-shadow: 2px 8px 20px 0px rgba(58, 137, 255, 0.2);
  border-radius: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dow_button_text {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-left: 10px;
}
</style>